import _objectSpread from "/Users/lianxiang/star/htcode/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.function.name.js";
import SearchSelect from '../../components/course/Search';
import navigation from '../../components/common/Navigation';
import { qn_token_get, uploadFile } from '../../api/qiniuApI';
import * as qiniu from 'qiniu-js'; //引入七牛
import VersionActive from '../../components/popup/VersionActive';
export default {
  name: "CourseVersion",
  data: function data() {
    return {
      isFilterShow: false,
      //显示筛选
      currentRow: null,
      //当前选中的版本的数据，给活动管理用的
      currentLeftData: {
        id: 4,
        name: '活动',
        stdlevelId: 4,
        rank: 1
      },
      courseTypeList: [],
      // 筛选
      filter: [{
        placeholder: '课程类型',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '正常'
        }, {
          id: '-1',
          name: '禁用'
        }]
      }, {
        placeholder: '课程版本状态',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '正常'
        }, {
          id: '-1',
          name: '禁用'
        }]
      }, {
        placeholder: '课程版本名称',
        value: this.$route.query.resName,
        type: 'text',
        list: []
      }],
      localCourseData: JSON.parse(sessionStorage.getItem("course-" + this.$route.query.courseId)),
      //当前课程额数据
      userInfo: this.Ls.getParseToken('nof_userInfo'),
      //获取用户数据
      navData: [{
        //面包屑
        meta: {
          title: "课程"
        },
        path: "/course/list"
      }, {
        meta: {
          title: "课程中心"
        },
        path: "/course/list"
      }, {
        meta: {
          title: "课程管理"
        },
        path: "/course/list"
      }, {
        meta: {
          title: "版本管理"
        },
        path: "/course/structure"
      }],
      // List接口返回表格数据
      tableKey: 1,
      //表格的key
      tableData: [],
      total: 0,
      //页数总数
      currentPage: 1,
      // 当前页数
      pageSize: 20,
      //每页个数
      // drawer抽屉-数据
      drawer: false,
      //是否显示弹框
      direction: "rtl",
      drawerState: "",
      drawerData: {
        courseId: this.$route.query.courseId,
        structureTypeId: "",
        //课程类型id
        name: "",
        independent: 1,
        //是否独立展示
        rank: "",
        //排序
        state: 1 //1启用 -1禁用
      },

      // dialog弹框-删除弹框
      delId: "",
      centerDialogVisible: false // 删除弹框显示隐藏
    };
  },

  watch: {
    '$route': 'initData'
  },
  components: {
    SearchSelect: SearchSelect,
    navigation: navigation,
    VersionActive: VersionActive
  },
  mounted: function mounted() {
    //设置面包屑
    this.$emit('navigation', this.navData);
  },
  computed: {
    //判断当前是第几层
    /*    checkStructure(){
          if(!this.structureDrop || this.structureDrop.length == 0) return  -1;
          for (let i = 0; i < this.structureDrop.length; i++) {
            if(this.$route.query.definitionId == this.structureDrop[i].id){
              return i;
            }
          }
          return -1;
        },*/
  },
  created: function created() {
    //设置下筛选的课程类型
    this.filter[0].list = [];
    this.courseTypeList = this.localCourseData.coursetype;
    for (var i = 0; i < this.courseTypeList.length; i++) {
      var json = {
        id: this.courseTypeList[i].structureTypeId,
        name: this.courseTypeList[i].structureTypeName
      };
      this.filter[0].list.push(json);
    }
    console.log("this.filter", this.filter);
    //显示筛选
    this.isFilterShow = true;

    //获取七牛token
    this.getToken();
    //获取课程结构
    this.getStructuredefinitionDrop();
    //获取数据
    this.initData();
    //获取全部课程类型
    this.structureTypeGetAll();
  },
  methods: {
    //筛选功能
    search: function search(filterArr) {
      var _this = this;
      this.service.axiosUtils({
        requestName: "coursecategoryGetList",
        data: {
          structureTypeId: filterArr[0].value,
          state: filterArr[1].value,
          name: filterArr[2].value,
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          pageSize: this.pageSize,
          pageIndex: this.currentPage,
          courseId: this.$route.query.courseId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this.tableData = res.data.data;
            _this.total = res.data.total;
          } else {
            _this.$message.error(res.message);
          }
        }
      });
    },
    //显示活动管理
    showVersionActive: function showVersionActive(row) {
      this.currentRow = row;
      // showDrawer(postData,row)
      this.$refs.versionActive.showDrawer({}, this.currentRow);
    },
    //获取左边的侧边栏
    getStructuredefinitionDrop: function getStructuredefinitionDrop() {
      var _this2 = this;
      this.service.axiosUtils({
        requestName: 'getStructuredefinitionDrop',
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          courseId: this.localCourseData.id,
          definitionId: 1
        },
        sucFn: function sucFn(res) {
          _this2.structureDrop = res.data;

          // var checkIndex=this.checkStructureN();

          // this.currentLeftData=this.structureDrop[checkIndex];

          /*
                    //判断当前层级是否显示同步 层级不固定了，注释了吧
                    if(checkIndex < 1){
                      this.structureEdit.list[5].buttonList[0].isHidden=true;
                    }else{
                      this.structureEdit.list[5].buttonList[0].isHidden=false;
                    }
          
                    //循环该出现几层
                    for (let i = 0; i < this.structureDrop.length; i++) {
                      let json={
                        placeholder:"请选择"+this.structureDrop[i].name
                      }
                      //
          
                      if(this.checkStructureN(this.structureDrop[i].id) < checkIndex){
                        //this.filter.list.push(1)
                        json.isShow=true;
                      }else{
                        json.isShow=false;
                        //this.filter.list.push(2)
                      }
                      this.filter.list.push(json)
                    }
          
          */

          /*判断是否需要出现下拉筛选*/
          // this.changeViewFilter(checkIndex)
        }
      });
    },
    //获取全部课程类型
    structureTypeGetAll: function structureTypeGetAll() {
      /* this.service.axiosUtils({
         requestName: 'structureTypeGetAll',
         data: {
           userId:this.userInfo.userId,
           roleId:this.userInfo.roleId,
         },
         sucFn: res => {
           this.courseTypeList=res.data;
         }
       })*/
      this.courseTypeList = this.localCourseData.coursetype;
    },
    //验证是否选择了课程类型的方法
    validateArray: function validateArray(rule, value, callback) {
      // console.log(88888888,rule,value,callback)
      if (value && value.length > 0) {
        callback();
      } else {
        callback(new Error('请选择课程类型'));
      }
    },
    //重置
    reload: function reload() {
      this.filter[0].value = "";
      this.filter[1].value = "";
      this.filter[2].value = "";
      this.initData();
    },
    //搜索资源
    searchNameList: function searchNameList(filterArrj) {
      var _this3 = this;
      var name = filterArrj[1].value;
      var state = filterArrj[0].value;
      var postData = {
        courseId: this.$route.query.courseId
      };
      var query = _objectSpread({}, this.$route.query);
      if (name) {
        // postData.resName=name;
        query.resName = name;
      } else {
        delete query.resName;
      }
      if (state) {
        // postData.state=state;
        query.state = state;
      } else {
        delete query.state;
      }
      this.currentTree = {};
      delete query.tree;
      var path = this.$route.path;
      /*      //收起所有节点
            this.defaultExpandAll=false;*/
      this.$router.replace({
        path: path,
        query: query
      });
      this.$nextTick(function () {
        //清空高亮
        _this3.$refs.tree.setCurrentKey(null);
        //全部收起
        _this3.expandHandle();
      });

      //搜索
      /* this.service.axiosUtils({
         requestName: 'courseresourceGetList',
         data: postData,
         sucFn: res => {
           this.tableData=res.data;
         }
       })*/
    },
    //获取token
    getToken: function getToken() {
      var _this4 = this;
      qn_token_get().then(function (res) {
        _this4.tokenData = res;
      });
    },
    // 添加函数
    addFun: function addFun() {
      this.drawerState = "add";
      this.drawer = true;
    },
    // 初始化数据
    initData: function initData() {
      var _this5 = this;
      this.service.axiosUtils({
        requestName: "coursecategoryGetList",
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          pageSize: this.pageSize,
          pageIndex: this.currentPage,
          courseId: this.$route.query.courseId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this5.tableData = res.data.data;
            _this5.total = res.data.total;
          } else {
            _this5.$message.error(res.message);
          }
        }
      });
    },
    // page-页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      this.initData();
    },
    // page-页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.initData();
    },
    // table-修改正常/禁用状态
    editState: function editState(row) {
      var _this6 = this;
      var rowState = "";
      if (row.state === 1) {
        rowState = -1;
      } else {
        rowState = 1;
      }
      this.service.axiosUtils({
        requestName: "coursecategoryChangeState",
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          id: row.id,
          state: rowState
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this6.$toast.success(res.message);
            if (row.state === 1) row.state = -1;else row.state = 1;
          } else {
            _this6.$toast.error(res.message);
          }
        }
      });
    },
    // table-中操作函数
    detail: function detail(type, val) {
      var _this7 = this;
      if (type === "edit") {
        this.drawerState = "edit";
        this.drawer = true;
        setTimeout(function () {
          /*          this.drawerData = {
                      name: val.name,
                      alias: val.alias,
                      value: val.value,
                      description: val.description,
                      rank: val.rank,
                      state: val.state,
                      id: val.id,
                    };*/
          console.log("课程数据", _this7.localCourseData);
          _this7.drawerData = {
            id: val.id,
            courseId: _this7.localCourseData.id,
            structureTypeId: val.structureTypeId,
            name: val.name,
            independent: val.independent,
            rank: val.rank,
            state: val.state
          };
        }, 0);
      } else if (type === "del") {
        this.centerDialogVisible = true;
        this.delId = val.id;
      } else {}
    },
    // drawer抽屉-取消函数
    cancelClick: function cancelClick() {
      this.$nextTick(function () {
        this.$refs["form"].resetFields();
      });
      this.drawer = false;
    },
    // drawer抽屉-确定函数
    confirmClick: function confirmClick(formName) {
      var _this8 = this;
      var that = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var url = "";
          that.drawerData.userId = that.userInfo.userId;
          that.drawerData.roleId = that.userInfo.roleId;
          if (that.drawerState === "edit") {
            //修改
            url = "editCourseCategory";
          } else {
            //添加课程类型
            url = "addCourseCategory";
          }
          that.service.axiosUtils({
            requestName: url,
            data: that.drawerData,
            sucFn: function sucFn(res) {
              if (res.status === 1) {
                that.$toast.success(res.message);
                that.drawer = false;
                that.drawerData = {
                  courseId: _this8.$route.query.courseId,
                  structureTypeId: "",
                  //课程类型id
                  name: "",
                  independent: 1,
                  //是否独立展示
                  rank: "",
                  //排序
                  state: 1 //1启用 -1禁用
                };

                if (that.drawerState === "add") {
                  that.currentPage = 1;
                }
                that.$nextTick(function () {
                  that.$refs["form"].resetFields();
                });
                that.initData();
              } else {
                that.$toast.error(res.message);
              }
            }
          });
        }
      });
    },
    // dialog弹框-删除弹框确定函数
    confirmFun: function confirmFun() {
      var _this9 = this;
      //删除当前的文件类型
      this.service.axiosUtils({
        requestName: "deleteCourseCategory",
        data: {
          userId: this.userId,
          roleId: this.roleId,
          id: this.delId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this9.$toast.success(res.message);
            _this9.delId = "";
            _this9.centerDialogVisible = false;
            _this9.initData();
          } else {
            _this9.$toast.error(res.message);
          }
        }
      });
    }
  },
  beforeRouteLeave: function beforeRouteLeave() {}
};